.update-page {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.update-page h1 {
  margin-bottom: 20px;
}

/*Dispositivos Móveis */
@media only screen and (max-width: 768px) {
  .update-page {
    padding: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}