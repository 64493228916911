.home-description {
  background-color: #bebedf;
  position: relative;
  width: 75%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 80px;
  border-radius: 10px;
  padding: 40px;
}

.home-description p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin: 20px 0;
}

.download-home-button {
  background-color: #5e60a9;
  color: white;
  padding: 15px 30px;
  font-size: 1.2em;
  border: none;
  text-decoration: none;
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  margin-top: 20px;
}

.home-description-right ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.home-description-right ul li {
  margin: 0;
  padding: 0;
}

.download-home-button {
  margin-top: 30px;
  width: 40%;
  text-align: center;
}


@media (max-width: 768px) {
  .home-description {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px;
    padding: 20px;
    width: 85%;
  }

  .home-description-left img {
    width: 80%;

  }

  .home-description-right {
    margin-top: 1px;
  }


  .home-description-right ul li strong {
    display: block;
    font-size: 1em;
  }

  .home-description-right p {
    font-size: 1rem;
  }

  .download-home-button {
    margin-top: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    align-items: center;
    text-align: center;
  }


}