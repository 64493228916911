.download-unknown-source-description {
  background-color: transparent;
  position: relative;
  width: 80%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 80px;
  margin-top: 10px;
  border-radius: 10px;
}

.download-unknown-source-divider {
  width: 1px;
  height: 100%;
  background-color: #ccc;
  margin: 0 20px; /* Espaçamento ao redor da linha */
  display: none; /* Esconda a linha por padrão */
}

.download-unknown-source-description-right h2 {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.download-unknown-source-description-right h2 span {
  margin-left: 10px;
}

.download-unknown-source-description-right ul {
  margin-top: 10px;
}

.download-unknown-source-description-left {
  width: 45%;
  padding: 20px;
}

.download-unknown-source-description-right {
  width: 50%;
  padding: 20px;
}

.download-unknown-source-description-right ul {
  list-style-type: none;
  padding: 0;
}

.download-unknown-source-description-right li {
  margin-bottom: 20px;
}

.download-unknown-source-description-right ul ul {
  margin-left: 20px;
  margin-bottom: 10px;
}

.download-unknown-source-description-right li ul li {
  margin-bottom: 10px;
}

.download-unknown-source-description-right h2 {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: none
}

.download-unknown-source-description-right.expanded h2 {
  transition: none;
}


h1, h2, h3 {
  margin-bottom: 20px;
}

p {
  margin-bottom: 15px;
}



/* Responsividade para mobile */
@media (max-width: 768px) {
  .download-unknown-source-description {
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
  }

  .download-unknown-source-description-left,
  .download-unknown-source-description-right {
    width: 100%;
    padding: 10px;
    text-align: center; 
    cursor: pointer;
    transition: none
  }

  .download-unknown-source-description-left h1 {
    text-align: center; 
    cursor: pointer;
    transition: none
  }

  .download-unknown-source-description-right ul ul {
    margin-left: 10px;
  }
}
