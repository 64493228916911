.resources-description {
  background-color: #bebedf;
  position: relative;
  width: 80%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 80px 80px;
  border-radius: 10px;
}

.resources-description-left {
  width: 30%;
  margin-left: 100px;
  text-align: center;
}

.resources-description-right ul {
  list-style-type: none;
  padding: 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-right: 300px;
  margin-left: 300px;
  padding: 20px;
  margin: 40px;
  padding: 40px;

}

.resources-description-right li {
  margin-bottom: 20px;
}

.resources-description-right li p {
  margin: 10px;
}

@media (max-width: 768px) {


  .resources-description {
    flex-direction: column;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 85%;
  }

  .resources-description-left {
    margin-left: 0px;
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .resources-description-right {
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .resources-description-right ul {
    margin-left: 0;
    margin-right: 0;
    padding: 0px;
    margin: 0px;

  }
}