.download-description-container {
  background-color: transparent;
  position: relative;
  width: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border-radius: 10px;
  padding: 20px;
}

.download-description {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.download-button {
  background-color: transparent;
  border: none;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.download-button img {
  max-width: 150px;
  max-height: 150px;
  height: auto;
  transition: transform 0.2s;
}

.download-button img:hover {
  transform: scale(1.05);
}

.google-play-button img {
  max-width: 180px;
  height: auto;
}

@media (max-width: 768px) {
  .download-description-container {
    width: 90%;
    margin: 6px;
    border-radius: 10px;
    padding: 10px;
  }

  .download-button {
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .download-button img {
    max-width: 120px;
  }

  .google-play-button img {
    max-width: 146px;
    height: auto;
  }
}