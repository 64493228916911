.download-page {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.download-page h1 {
  font-size: 24px;
  margin-bottom: 20px;

}

/*Dispositivos Móveis */
@media only screen and (max-width: 768px) {
  .download-page {
    padding: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
}