.banner-container {
  position: relative;
  width: 82%;
  height: 200px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px; 
}


.banner-image-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;

}

.banner-image-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.banner-content {
  width: 50%;
  padding: 20px;
  text-align: left;
  color: black;
}

.banner-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.banner-content p {
  font-size: 1.5rem;
}

/*Dispositivos Móveis */
@media only screen and (max-width: 768px) {
  .banner-container {
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 1px;
    margin-right: 1px;
  }

  .banner-image-container {
    width: 90%;
    height: auto;
  }

  .banner-image-container img {
    max-width: 100%;
    max-height: 100%;
  }

  .banner-content {
    width: 100%;
    padding: 10px;
  }

  .banner-content h1 {
    font-size: 2rem;
  }

  .banner-content p {
    font-size: 1.2rem;
  }
}