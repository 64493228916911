.update-download {
  background-color: #bebedf;
  position: relative;
  width: 75%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  border-radius: 10px;
  padding: 40px;
}

.update-download p {
  line-height: 1.6;
  margin: 15px 0;
  text-align: center;
}

@media (max-width: 768px) {
  .update-download {
    padding: 20px;
    margin: 10px auto;
    width: 90%;
  }

  .update-download p {
    font-size: 1rem;
    margin: 10px 0;
  }
}