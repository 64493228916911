.AppCarousel {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.carousel-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* Garante que os elementos se distribuam uniformemente */
  padding: 20px;
  border: 1px solid #292045;
  border-radius: 10px;
  margin-bottom: 20px;
  margin: 10px;
  height: 500px;
  /* Altura fixa para manter o tamanho do carousel consistente */
  overflow: hidden;
  /* Evita que o conteúdo ultrapasse o tamanho do contêiner */
}

.carousel-item img {
  width: 100%;
  height: 350px;
  object-fit: contain;
  border-radius: 10px;
  overflow: hidden;
}

.carousel-item h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
  word-wrap: break-word;
  /* Permite que palavras longas sejam quebradas */
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  /* Permite que o texto quebre para a próxima linha */
}

@media (max-width: 768px) {
  .carousel-item h3 {
    font-size: 1.1rem;
    /* Reduz um pouco o tamanho da fonte no mobile */
  }
}


.carousel-item p {
  font-size: 1rem;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Limita o número de linhas do parágrafo */
  -webkit-box-orient: vertical;
}