.download-resources-description {
  background-color: #bebedf;
  position: relative;
  width: 80%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px auto;
  border-radius: 10px;
  padding: 20px;
}

.download-resources-description-left {
  width: 40%;
  margin: 0 20px;
  text-align: center;
}

.download-resources-description-right {
  width: 40%;
  margin: 0 20px;
  text-align: center;
}

.download-resources-description-right ul {
  list-style-type: none;
  padding: 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  margin: 10px;
}

.download-resources-description-right li {
  margin-bottom: 20px;
}

.download-resources-description-right li p {
  margin: 10px;
}

.download-resources-description-right ul li span {
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
}

@media (max-width: 768px) {

  .download-resources-description {
    flex-direction: column;
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 90%;
  }

  .download-resources-description-left,
  .download-resources-description-right {
    margin: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
  }

  .download-resources-description-right ul {
    margin: 0;
    padding: 0;
  }
}