.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  margin: 0;
  background-color: #292045;
  color: #fff;
  overflow-x: hidden;
}

.header h1 {
  margin: 0;
}

.header nav ul {
  list-style: none;
  display: flex;
  gap: 1rem;
  margin: 0;
  padding: 0;
}

.header nav ul li {
  margin: 0;
}

.header nav ul li a {
  color: #fff;
  text-decoration: none;
}

.header nav ul li a:hover {
  text-decoration: underline;
}

.logo {
  font-size: 22px;
}

.logo-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-img {
  height: 1.5em;
  margin-right: 0.3em;
}


.logo a {
  text-decoration: none;
  color: #fff;
}

/*Dispositivos Móveis */
@media only screen and (max-width: 768px) {
  .logo {
    font-size: 20px;
  }
}